<template>
  <div class="row mx-0 justify-content-center">
    <LoginSection />
    <div class="col-12 mb-3 text-center" >
      <div class="row mx-0 justify-content-center">
        <div class="col-12 mb-3 text-center font19" >
          Your Details
        </div>
        <TabsComponent :tabOptions="donorTypes" v-model="anonymousValue.donorType" />
      </div>
    </div>
    <div class="col-11 col-md-8">
      <AnonymousUserInfo v-model:anonymous="anonymousValue" :donorType="anonymousValue.donorType" v-model:isValidNumber="anonymousValue.isValidNumber" />
    </div>
    <div class="col-12 col-md-9 pt-2 mt-1 border-top"></div>
    <div class="col-11 col-md-8">
      <AddressDetails v-model="anonymousValue.address"/>
    </div>
  </div>
  <!-- END PAGE 1 -->
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    LoginSection: defineAsyncComponent(() => import('../../components/LoginSection.vue')),
    TabsComponent: defineAsyncComponent(() => import('../../components/TabsComponent.vue')),
    AnonymousUserInfo: defineAsyncComponent(() => import('./views/AnonymousUserInfo.vue')),
    AddressDetails: defineAsyncComponent(() => import('./views/AddressDetails.vue'))
  },
  name: 'Wizard Anonymous Details',
  props: ['anonymous'],
  emits: ['update:anonymous'],
  data () {
    return {
      donorTypes: ['individual', 'organisation']
    }
  },
  computed: {
    anonymousValue: {
      get () {
        return this.anonymous
      },
      set (val) {
        this.$emit('update:anonymous', val)
      }
    }
  }
}

</script>
